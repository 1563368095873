.tables-list {
}

.tables-list .tables {
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: flex-start;
}

.tables-list .tables button {
	width: 100%;
	border: 1px solid #000;
	border-radius: 5px;
	padding: 10px 10px 10px 10px;
	display: grid;
	grid-template-columns: 2fr 1fr 1fr;
	text-align: left;
	align-items: center;
	background-color: var(--c3);
	position: relative;
	gap: 10px;
}

.tables-list .tables button.open {
	background-color: #9dde8b;
	color: #000;
}

.tables-list .tables button.warning {
	background-color: #ffc300;
}

.tables-list .tables button.visible {
	background-color: #919090;
	color: #fff;
}

.tables-list .tables button.open.visible {
	background-color: #40a578;
	color: #fff;
}

.tables-list .tables button.selected {
	border: 5px solid lightblue;
	padding: 6px;
}

.tables-list .tables button .status {
	position: absolute;
}

.tables-list .tables button .inline-total {
	text-align: right;
	font-weight: bold;
	font-size: 16px;
	align-self: flex-end;
}

.tables-list .header-tables {
	background-color: var(--c1);
	color: var(--c1c);
	padding: 5px 0;
}

.tables-list .line-separator {
	width: 100%;
	border-top: 0.1px solid #ccc;
}

.tables-list .open .line-separator {
	border-top: 0.1px solid var(--c1c);
}

.tables-list .diner {
	display: flex;
	gap: 5px;
	align-items: center;
	text-align: left;
	padding: 0 5px;
}
.tables-list .diner svg,
.tables-list .flow svg {
	fill: var(--c2) !important;
}

.tables-list .flow {
	display: flex;
	gap: 5px;
	align-items: center;
	padding: 0 5px;
}

.tables-list .name {
	font-weight: bold;
	font-size: 14px;
}

.tables-list .badge {
	font-weight: bold;
	font-size: 12px;
	background-color: var(--c2);
	color: #fff;
	padding: 3px 5px;
	border-radius: 5px;
	position: absolute;
	top: 3px;
	right: 5px;
}

.tables-list .services {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.tables-list .total .total-value {
	font-size: 16px;
	font-weight: bold;
	padding-bottom: 5px;
}

.tables-list .rooms {
	display: flex;
	gap: 10px;
	margin-bottom: 15px;
	overflow: auto;
	width: 100%;
	flex-shrink: 0;
}

.tables-list .rooms button {
	color: var(--c1);
	background-color: var(--c3);
	font-weight: bold;
	text-transform: uppercase;
	border: 0;
	padding: 10px 20px;
	border-radius: 5px;
}

.tables-list .rooms button.active {
	background-color: var(--c1);
	color: var(--c1c);
}

.tables-list {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.tables-list .tables button .inline-button {
	display: inline-block;
	width: auto;
	border: 0;
	margin: 0;
	padding: 0 5px;
	background-color: transparent;
	color: var(--c2);
}

.tables-sidebar-container {
	display: grid;
	gap: 10px;
	grid-template-columns: 2fr 1fr;
	flex-grow: 1;
	overflow: hidden;
}

.tables-sidebar-container > div {
	height: 100%;
	overflow: auto;
}

.tables-sidebar .sidebar {
	background-color: #eee;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	flex-basis: auto;
}

.tables-sidebar .sidebar .sidebar-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: bold;
	background-color: var(--c1);
	color: var(--c1c);
	padding: 10px;
	flex-shrink: 0;
}

.tables-sidebar .sidebar .sidebar-content {
	flex-grow: 1;
	overflow: auto;
	padding: 5px;
}

.tables-sidebar .sidebar .sidebar-footer {
	background-color: var(--c1);
	color: var(--c1c);
	padding: 10px;
	flex-shrink: 0;
	display: flex;
	gap: 5px;
}

.tables-sidebar .sidebar .order {
	margin-bottom: 5px;
	border-radius: 5px;
	background-color: var(--c1c);
	color: var(--c1);
	padding: 5px;
}

.tables-sidebar .sidebar .order-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 5px;
	border-bottom: 1px solid var(--c1);
	padding-bottom: 5px;
	margin-bottom: 5px;
}

.tables-sidebar .sidebar .order-info {
	font-size: 12px;
	font-weight: bold;
}

.tables-sidebar .sidebar .order-products > div {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
}

.tables-sidebar .sidebar .order-footer {
	text-align: right;
	font-weight: bold;
	border-top: 1px solid var(--c1);
	padding-top: 5px;
	margin-top: 5px;
	display: flex;
	justify-content: space-between;
}

.tables-sidebar .sidebar .order-footer > div:nth-child(1) {
	text-align: left;
	font-size: 12px;
	display: grid;
	gap: 5px;
}

.tables-sidebar .sidebar .order-footer > div:nth-child(1) > div {
	display: flex;
	gap: 5px;
	align-items: center;
}

.tables-sidebar .sidebar .order-footer > div:nth-child(1) svg {
	width: 15px;
	height: 15px;
}

.tables-board {
	background-color: #ddbda1;
	width: 100%;
	flex-grow: 1;
	position: relative;
}

.tables-board .snap-point {
	width: 50px;
	height: 50px;
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
	border-top: 1px solid #000;
	border-left: 1px solid #000;
}

.tables-board > div {
	position: absolute;
	display: block;
}

.tables-board .table {
	height: 100%;
	background-color: var(--c1c);
	color: var(--c1);
	text-align: center;
	font-size: 10px;
	border: 1px solid var(--c1);
	border-radius: 5px;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.tables-board .table.open {
	background-color: var(--c1);
	color: var(--c1c);
}

.tables-board .table.warning {
	background-color: #ffc300;
	color: var(--c1c);
}

.tables-list .table.selected {
	outline: 5px solid green;
}

.tables-board .edit-panel {
	padding-top: 5px;
}

.tables-board .edit-panel select {
	padding: 2px;
}
