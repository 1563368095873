#notifications {
	position: fixed;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	gap: 5px;
	padding: 5px;
}
#notifications > div {
	border: 1px solid #fff;
	border-radius: 5px;
	padding: 5px;
	display: flex;
	flex-direction: column;
	gap: 5px;
	background-color: #fff;
	max-width: 250px;
}
#notifications > div.info {
	background-color: var(--success);
	color: #fff;
}
#notifications > div.error {
	background-color: var(--error);
	color: #fff;
}
#notifications > div.warning {
	background-color: var(--warning);
	color: #fff;
}
#notifications > div .notifications-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 5px;
}
#notifications > div .title {
	font-weight: bold;
	font-size: 14px;
}
#notifications > div .notifications-header button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 25px;
	height: 25px;
	padding: 0;
	margin: 0;
	border: 0;
	border-radius: 5px;
	font-weight: bold;
	font-size: 16px;
	background-color: var(--c1);
	color: var(--c1c);
	line-height: 0;
}
#notifications > div .message {
	font-size: 12px;
}
