.sidebar {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  position: relative;
}

@media (min-width: 1280px) {
  .sidebar {
    flex-basis: 250px;
  }
}

.sidebar .modes-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.sidebar .modes-buttons .icon-category {
  display: block;
}
.sidebar .modes-buttons .icon-category svg {
  font-size: 20px;
}
.sidebar .modes-buttons .name-category {
  display: none;
}

@media (min-width: 1280px) {
  .sidebar .modes-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .sidebar .modes-buttons .icon-category {
    display: none;
  }
  .sidebar .modes-buttons .name-category {
    display: block;
  }
}

.sidebar .modes-buttons button {
  background-color: var(--c1);
  color: var(--c1c);
  border: 0;
  padding: 10px;
  font-weight: bold;
  text-transform: uppercase;
  writing-mode: vertical-rl;
  border-radius: 5px;
}

@media (min-width: 1280px) {
  .sidebar .modes-buttons button {
    border-radius: 0;
    padding: 10px 0;
    writing-mode: unset;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.sidebar .modes-buttons button.selected {
  background-color: var(--c1c);
  color: var(--c1);
}

.sidebar .scroll {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: auto;
  flex-grow: 1;
  background-color: var(--c1c);

  position: absolute;
  width: 250px;
  left: 45px;
  top: 0px;
  z-index: 10;
  border-radius: 5px;
  border: 1px solid var(--c1);
  padding: 10px;
  height: 100%;
  display: none;
}

@media (min-width: 1280px) {
  .sidebar .scroll {
    display: flex;
    position: static;
    border-radius: 5px;
    width: auto;
    border: 0;
    padding: 30px 0 10px 10px;
    height: auto;
    z-index: auto;
  }

  .sidebar .scroll.mode-1 {
    border-top-left-radius: 0;
  }

  .sidebar .scroll.mode-2 {
    border-top-right-radius: 0;
  }
}

.sidebar .scroll.open {
  display: flex;
}

.sidebar .scroll > div {
  display: flex;
  flex-direction: column;
}

.sidebar .scroll button {
  background-color: unset;
  border: unset;
  color: black;
  justify-content: left;
  text-transform: uppercase;
  padding: 15px;
  font-weight: bold;
  text-align: left;
  font-size: 15px;
}

.sidebar .scroll button.selected {
  background-color: var(--c1);
  color: var(--c1c);
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.sidebar .course-name {
  font-weight: bold;
  padding: 10px;
  background-color: var(--c2);
  color: var(--c2c);
  border-radius: 5px 0px 0px 5px;
  margin-bottom: 5px;
  text-transform: uppercase;
  line-height: 14px;
}
