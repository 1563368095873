.login {
  background-color: aquamarine;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: var(--c1);
}

.login .content {
  background-color: #fff;
  padding: 25px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 400px;
}

.login .content h2 {
  text-align: center;
}

.login select,
.login input {
  border-color: #ccc;
}
.login-logo {
  text-align: center;
}

.login-logo img {
  max-width: 100%;
  padding: 20px;
  width: 250px;
}
