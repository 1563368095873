.pin-pad .pin-pad-buttons {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 5px;
	margin-bottom: 15px;
}

.pin-pad .pin-pad-buttons button {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80px;
	font-size: 30px;
	font-weight: bold;
	border: 0;
	background-color: var(--c1);
	color: var(--c1c);
}

.pin-pad p {
	margin: 0;
	font-size: 20px;
	text-align: center;
	margin-bottom: 15px;
}

.pin-pad input {
	margin-bottom: 15px;
	font-size: 30px;
	text-align: center;
}

.pin-pad .submit {
	width: 100%;
	font-size: 30px;
	text-align: center;
	padding: 15px;
	border: 0;
	background-color: var(--c2);
	color: var(--c2c);
}
