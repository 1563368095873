.header {
	background-color: var(--c1);
	flex-shrink: 0;
	display: flex;
	align-items: flex-start;
	gap: 10px;
}

.header .separator {
	width: 1px;
	background-color: var(--c1c);
	opacity: 0.5;
	height: 100%;
}

.button-labeled {
	background-color: transparent;
	border: 0;
	display: flex;
	gap: 5px;
	align-items: center;
	position: relative;
	margin: 0;
	padding: 0;
}

.button-labeled.vertical {
	flex-direction: column;
	min-width: 60px;
	max-width: 150px;
}

.button-labeled.vertical .info {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.button-labeled.vertical .label {
	font-size: 10px;
	line-height: 12px;
}

.button-labeled .icon {
	width: 40px;
	height: 40px;
	background-color: var(--c1c);
	color: var(--c1);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
}

.button-labeled.selected .icon {
	background-color: var(--c2);
	color: var(--c2c);
}

.button-labeled .icon svg {
	font-size: 25px;
}

.button-labeled .info {
	text-align: left;
}
.button-labeled .info .value {
	font-size: 10px;
}

.button-labeled .label {
	color: var(--c1c);
	font-size: 12px;
	font-weight: bold;
	line-height: 14px;
}

.button-labeled .value {
	color: var(--c1c);
	font-size: 12px;
	line-height: 14px;
}

.button-labeled.ellipsis .value {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.button-labeled .error {
	color: var(--c2c);
	background-color: var(--c2);
	border-radius: 3px;
	padding: 1px 3px;
	margin-top: 3px;
	font-size: 10px;
	display: flex;
	text-transform: uppercase;
	font-weight: 600;
	gap: 3px;
	align-items: center;
	width: fit-content;
}

.button-labeled .error-icon {
	font-size: 7px;
	border: 1px solid #fff;
	border-radius: 100px;
}

.button-labeled.disabled button {
	opacity: 50%;
}

.header-close-button {
	width: 35px;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	border-radius: 5px;
	background-color: var(--c3);
	color: var(--c3c);
	font-size: 32px;
	border: 0;
}
