.lock {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #fff;
}

.lock .content {
	background-color: #fff;
	padding: 10px;
	border-radius: 5px;
	width: 100%;
	max-width: 250px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 30px;
}

.lock img {
	max-width: 100%;
}
