.cart {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: 10px;
	overflow: hidden;
}

.cart-box {
	padding: 10px;
	border-radius: 5px;
	background-color: var(--c1c);
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.cart-box.expand {
	flex-grow: 1;
	overflow: hidden;
}
.cart-box.order-notes {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.cart .scroll {
	display: flex;
	flex-direction: column;
	gap: 5px;
	overflow: auto;
	flex-grow: 1;
}

.cart .cart-header {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.cart .cart-product-line {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: 5px;
	padding: 10px;
	border: 1px solid var(--c1);
	border-radius: 5px;
}

.cart .cart-product-line-right {
	display: flex;
	align-items: center;
	gap: 5px;
}

.cart .cart-product-line-name {
	flex-grow: 1;
	border: 0;
	margin: 0;
	padding: 0;
	text-align: left;
	font-size: 16px;
	background-color: transparent;
}
.cart .cart-product-line-name b {
	text-transform: uppercase;
}
.cart .cart-product-line-name .cart-product-name {
	font-weight: bold;
	padding-top: 4px;
}

.cart .cart-product-line-name .small {
	font-size: 11px;
	font-weight: 200;
}

.cart .cart-product-line-qty {
	font-weight: bold;
	text-align: center;
	width: 20px;
	padding-top: 4px;
}

.cart .cart-product-line-price {
	text-align: right;
	font-weight: bold;
}

.cart .subtotal {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: 500;
	font-size: 12px;
}

.cart .subtotal > * {
	display: flex;
	gap: 5px;
	align-items: center;
}

.cart .subtotal button {
	border-radius: 5px;
	font-size: 14px;
	padding: 0px;
	margin-right: 5px;
	background-color: unset;
	border: unset;
	color: var(--c1);
}

.cart .total {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	font-weight: 500;
	font-size: 20px;
	border-top: 1px dashed #000;
	padding-top: 10px;
	font-weight: bold;
	text-transform: uppercase;
}
.cart .total .total-price {
	font-size: 28px;
}

.discount {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.discount .container h3 {
	text-align: center;
}
.discount .container .discount-type {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	margin-bottom: 10px;
}

.discount .container .discount-type button {
	border: 0;
	background-color: var(--c3);
	color: var(--c5c);
	border-radius: 5px;
	padding: 10px 20px;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: bold;
}

.discount .container .discount-type button.selected {
	background-color: var(--c1);
	color: var(--c1c);
	border-radius: 5px;
}

.discount .button {
	font-size: 18px;
	text-transform: uppercase;
	font-weight: bold;
}

.cart-flows-buttons {
	display: flex;
	align-items: center;
	gap: 10px;
	overflow-x: auto;
	background-color: #fff;
	padding: 10px 0;
}

.cart-flows-buttons button {
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	padding: 5px 10px;
	flex-shrink: 0;
	background-color: var(--c3);
	color: var(--c3c);
	border: 0;
	border-radius: 5px;
	text-transform: uppercase;
	font-weight: bold;
}

.cart-flows-buttons button.selected {
	background-color: var(--c1);
	color: var(--c1c);
}

/* width */
.cart-flows-buttons::-webkit-scrollbar {
	width: 10px;
	height: 5px;
}

/* Track */
.cart-flows-buttons::-webkit-scrollbar-track {
	background: #eee;
}

/* Handle */
.cart-flows-buttons::-webkit-scrollbar-thumb {
	background: #ccc;
}

/* Handle on hover */
.cart-flows-buttons::-webkit-scrollbar-thumb:hover {
	background: rgb(143, 143, 143);
}

.cart-box.order-notes button.square {
	padding: 2px;
	width: 36px;
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cart-box.order-notes button.disabled {
	fill: #fff;
	color: #fff;
	background-color: #ccc;
}

.split-box {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
}

.split-box > div {
	background-color: #eee;
	padding: 5px;
	display: flex;
	flex-direction: column;
	gap: 5px;
	border-radius: 5px;
}

.split-box > div > button {
	background-color: var(--c1c);
	color: var(--c1);
	padding: 10px;
	border: 1px solid var(--c1);
	display: block;
	width: 100%;
	text-align: left;
	font-size: 16px;
	font-weight: bold;
	border-radius: 5px;
}

.split-box.totals > div {
	text-align: center;
	font-weight: bold;
	margin-top: 10px;
}

.keypad {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 75px;
	grid-template-rows: 20px 1fr 1fr 1fr 1fr 1fr;
	gap: 2px;
	height: 320px;
	background-color: #fff;
	border: 1px solid #fff;
	flex-shrink: 0;
	border-radius: 5px;
}

.keypad.closed {
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 20px 1fr;
	height: 100px;
}

.keypad .expand-button {
	height: 100%;
	background-color: #fff;
	color: var(--c2);
	display: flex;
	align-items: center;
	grid-column: 1 / span 5;
	font-size: 40px;
}

.keypad .keypad-amount {
	height: 100%;
	background-color: var(--c1);
	display: flex;
	align-items: center;
	grid-column: 1 / span 3;
	border-radius: 5px;
}

.keypad .keypad-amount-number {
	flex-grow: 1;
	height: 100%;
	color: var(--c1c);
	display: flex;
	align-items: center;
	padding: 0 10px;
	justify-content: flex-end;
	font-weight: bold;
	font-size: 20px;
}

.keypad .keypad-amount button {
	background-color: transparent;
	color: var(--c1c);
	width: 30px;
}

.keypad button {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 0;
	height: 100%;
	padding: 0;
	font-weight: bold;
	font-size: 20px;
	border-radius: 5px;
}

.keypad.closed .open-only {
	display: none;
}

.keypad button.light {
	background-color: var(--c3);
	color: var(--c3c);
}

.keypad button.dark {
	background-color: var(--c1);
	color: var(--c1c);
}

.keypad button.bill {
	background-color: var(--c2);
	color: var(--c2c);
	font-size: 11px;
	text-transform: uppercase;
	display: flex;
	flex-direction: column;
	gap: 2px;
	align-items: center;
	justify-content: center;
	position: relative;
}

.keypad button.bill .icon {
	font-size: 28px;
}

.keypad button.large {
	grid-area: 5 / 5 / span 2 / span 1;
	gap: 5px;
}
.keypad button.large svg {
	font-size: 40px;
}
.keypad.closed button.large {
	grid-area: auto;
}

.keypad button.disabled {
	opacity: 0.8;
}

.keypad .payed-icon {
	position: absolute;
	bottom: 5px;
	right: 5px;
	width: 10px;
	height: 10px;
}

.keypad .payed-icon svg {
	width: 100%;
	height: auto;
}

.cart-header-buttons-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.cart-header-buttons {
	display: flex;
	gap: 5px;
	align-items: center;
	position: relative;
}

.cart-header-buttons button {
	min-width: 35px;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	background-color: var(--c1);
	color: var(--c1c);
	padding: 0;
	font-size: 25px;
	border-radius: 5px;
	border: 0;
	padding: 5px;
}

.cart-header-buttons .label {
	text-align: left;
	font-size: 14px;
	font-weight: bold;
}

.cart-submenu {
	display: flex;
	flex-direction: column;
	gap: 5px;
	position: absolute;
	right: 0;
	top: 40px;
	background-color: #fff;
	padding: 5px;
	border: 1px solid #ccc;
	border-radius: 5px;
	z-index: 2;
	min-width: 200px;
}

.cart-submenu button {
	white-space: nowrap;
	padding: 10px;
	text-align: left;
	border: 0;
	background-color: var(--c1);
	color: var(--c1c);
	text-transform: uppercase;
	font-size: 14px;
	font-weight: bold;
	border-radius: 5px;
}

.cart-submenu button:hover {
	background-color: var(--c3);
	color: var(--c3c);
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100dvh;
	z-index: 1;
}

.payment {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100dvh;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	padding: 10px;
	gap: 10px;
}

.payment .payment-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.payment .payment-header .title {
	font-size: 30px;
	color: var(--c2);
	text-transform: uppercase;
	font-weight: bold;
}

.payment .payment-header button {
	background-color: var(--c1);
	color: var(--c1c);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 22px;
	width: 36px;
	height: 36px;
	padding: 0;
	border-radius: 5px;
	font-weight: bold;
}

.payment .cols {
	display: flex;
	gap: 10px;
	flex-grow: 1;
	overflow: hidden;
}

.payment .cols .left {
	flex-grow: 1;
}

.payment .cols .right {
	flex-shrink: 0;
	flex-basis: 350px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	height: 100%;
}

.payment .cols .right .right-content {
	flex-grow: 1;
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.payment .cols .right .right-content > div {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
}

.payment .cols .right .right-content > div > div {
	display: flex;
	align-items: center;
	gap: 10px;
}

.payment .cols .right .right-footer {
	flex-shrink: 0;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 10px;
}

.payment .cols .right .right-footer > div {
	font-weight: bold;
}

.payment .cols .right .right-footer > div > *:nth-child(1) {
	font-size: 14px;
}

.payment .cols .right .right-footer > div > *:nth-child(2) {
	font-size: 20px;
}
