.header-right {
  flex-shrink: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.header-right button {
  background-color: var(--c2);
  color: var(--c2c);
  border: 0;
  margin: 0;
  border-radius: 5px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 14px;
  padding: 10px;
}
.header-right button:hover {
  background-color: var(--c3);
  color: var(--c2);
}
.header-right button:hover > .badge {
  background-color: var(--c2);
  color: var(--c1c);
}
.header-right button .badge {
  background-color: #fff;
  border-radius: 100px;
  color: var(--c2);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
