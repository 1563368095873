.times-container {
  display: grid;
  gap: 10px;
}
.times-container .calendar {
  background-color: var(--c3);
  padding: 10px;
  border-radius: 5px;
}
.times-container h3 {
  margin-bottom: 0px !important;
  margin-top: 10px;
}
.calendar-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.calendar-plus-minus {
  display: flex;
  gap: 10px;
  align-items: center;
}

.calendar-plus-minus button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: var(--c2);
  color: var(--c2c);
  border: 0;
  font-size: 30px;
  border-radius: 100px;
}

.calendar-plus-minus-label {
  min-width: 85px;
  text-align: center;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.calendar-days div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--c1);
  color: var(--c1c);
  padding: 5px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 5px;
}

.calendar-days button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 0;
  height: 40px;
  background-color: var(--c1c);
  border-radius: 5px;
}

.calendar-days button.selected {
  background-color: var(--c2);
  color: var(--c2c);
}
