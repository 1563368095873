.products {
	display: flex;
	flex-direction: column;
	gap: 10px;
	height: 100%;
	width: 200px;
	flex-grow: 1;
	padding: 10px;
	background-color: var(--c1c);
	border-radius: 5px;
}
.products h3 {
	text-transform: uppercase;
	font-weight: bold;
	padding: 10px 0;
}
.products .scroll {
	overflow: auto;
	height: 100%;
}

.products .grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px;
}

@media (min-width: 1024px) {
	.products .grid {
		grid-template-columns: repeat(4, 1fr);
	}
}

@media (min-width: 1280px) {
	.products .grid {
		grid-template-columns: repeat(4, 1fr);
	}
}

@media (min-width: 1500px) {
	.products .grid {
		grid-template-columns: repeat(5, 1fr);
	}
}

.products button {
	border: 0;
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	aspect-ratio: 1/1;
	padding: 0;
}

.products button.auto {
	aspect-ratio: auto;
}

.product-info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 5px;
	padding: 15px 5px 15px 15px;
	background-color: var(--c1);
	color: var(--c1c);
	flex-grow: 1;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	height: 100%;
	text-align: left;
}

.product-name {
	font-weight: 600;
	flex-grow: 1;
	font-weight: bold;
	font-size: 18px;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
}

.product-price {
	font-size: 18px;
}

.product-color {
	background-color: var(--c1);
	color: var(--c1c);
	width: 10px;
	height: 100%;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	flex-shrink: 0;
}

.products .src {
	position: relative;
}

.products .src button {
	position: absolute;
	top: 4px;
	right: 4px;
	padding: 0;
	margin: 0;
	font-size: 20px;
	font-weight: bold;
	border: 1px solid #000;
	border-radius: 5px;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}
