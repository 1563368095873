@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:wght@100..900&display=swap");

* {
	box-sizing: border-box;
	font-family: "Inter", sans-serif !important;
}

:root {
	--c1: #213740;
	--c1c: #ffffff;
	--c1a9: #21374090;
	--c1a8: #21374050;
	--c2: #bf0426;
	--c2c: #fff;
	--c3: #f0f0f0;
	--c3c: #000;
	--c4: #000;
	--c4c: #ffffff;
	--confirmed: #fdf1cf;
	--confirmed-c: #000;
	--preparation: #ffe0b3;
	--preparation-c: #000;
	--ready: #d9ffdb;
	--ready-c: #000;
	--filled: #b6eeff;
	--filled-c: #000;
	--status1: rgb(253, 241, 207);
	--status2: rgb(255, 224, 179);
	--status3: rgb(217, 255, 219);
	--status4: rgb(182, 238, 255);
	--success: rgb(102, 186, 77);
	--error: red;
	--warning: rgb(226, 175, 6);
	--info: rgb(165, 237, 255);
}

html {
	height: 100dvh;
}

body {
	height: 100dvh;
	padding: 0;
	margin: 0;
}

#root {
	height: 100dvh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-weight: 500;
}

svg {
	display: block;
}

input:not([type="checkbox"]) {
	border-radius: 5px;
	padding: 10px 5px;
	border: 1px solid var(--c4);
	width: 100%;
	display: block;
}

textarea {
	border-radius: 5px;
	padding: 5px;
	border: 0;
	width: 100%;
	min-height: 150px;
	display: block;
	background-color: var(--c3);
}

select {
	border-radius: 5px;
	padding: 9px 5px;
	border: 1px solid var(--c4);
	width: 100%;
	display: block;
}

.text-success {
	color: var(--success);
}

.text-error {
	color: var(--error);
}

.text-info {
	color: var(--info);
}

.button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0px 5px;
	width: 100%;
	height: 36px;
	border: 2px solid var(--c1);
	background-color: var(--c1);
	text-transform: uppercase;
	color: var(--c4c);
	font-weight: 500;
	border-radius: 5px;
	font-size: 12px;
}

.button.icon {
	padding: 0;
}

.button.icon svg {
	font-size: 25px;
	width: 100%;
	height: auto;
}

.button.square {
	width: 36px;
	flex-shrink: 0;
}

.button.disabled {
	opacity: 0.8;
}

.button-1 {
	border: 2px solid var(--c1);
	background-color: var(--c1);
	color: var(--c1c);
}
.button-1:hover {
	border: 2px solid var(--c2);
	background-color: var(--c2);
	color: var(--c1c);
}

.button-2 {
	border: 2px solid var(--c2);
	background-color: var(--c2);
	color: var(--c2c);
}
.button-2:hover {
	border: 2px solid var(--c1);
	background-color: var(--c1);
	color: var(--c1c);
}

.button-3 {
	border: 2px solid var(--c3);
	background-color: var(--c3);
	color: var(--c3c);
}
.button-3:hover {
	border: 2px solid var(--c1);
	background-color: var(--c1);
	color: var(--c1c);
}

.button-4 {
	border: 2px solid var(--c4);
	background-color: var(--c4);
	color: var(--c4c);
}
.button-4:hover {
	border: 2px solid var(--c1);
	background-color: var(--c1);
	color: var(--c1c);
}

.main-container {
	height: 100%;
	display: flex;
	background-color: var(--c1);
	padding: 10px;
	gap: 10px;
}

.main-container > .left {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.main-container > .left > .main {
	flex-grow: 1;
	display: flex;
	overflow: hidden;
	gap: 10px;
}

.main-container > .right {
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	width: 330px;
	gap: 10px;
}

@media (min-width: 1280px) {
	.main-container > .right {
		width: 400px;
	}
}

.list {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.list.mode-2 {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 5px;
}
.list.mode-3 {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 5px;
}
.list.mode-4 {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 5px;
}
.list.mode-5 {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 5px;
}
.list.mode-6 {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 5px;
}
.list.mode-6 .list-element {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	aspect-ratio: 1 / 1;
	flex-direction: column;
	gap: 5px;
	font-size: 14px;
}

.list-element {
	display: block;
	background-color: var(--c1);
	color: var(--c1c);
	padding: 14px;
	border-radius: 5px;
}

.list-element:hover {
	background-color: var(--c2);
}

.list-element .icon {
	width: 100px;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.list-element .icon img {
	max-width: 100%;
	max-height: 100%;
}

.list-element.table-header {
}

.list-element.table {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	align-items: center;
	gap: 10px;
	background-color: #fff;
	color: #000;
}

.list-element .group,
.list-element .subgroup {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
}

.list-element .subgroup {
	margin-left: 15px;
	border-bottom: 1px solid var(--c3);
	padding: 10px 0;
}

.list-element .group .client-info .client-name {
	font-weight: 600;
	text-transform: uppercase;
}
.list-element .group .client-info .small {
	font-weight: 200;
}

.list-element .group .left {
	flex-grow: 1;
}

.list-element.table .center {
	text-align: center;
}

.list-element.table .buttons,
.list-element .group .buttons,
.list-element .subgroup .buttons {
	display: flex;
	gap: 10px;
}
.list-element .subgroup .buttons .icon-button {
	padding: 5px 5px !important;
	font-size: 24px;
}
.list-element .group .buttons .icon-button {
	padding: 5px 5px !important;
	font-size: 24px;
}

.list-element.table .buttons {
	justify-content: flex-end;
	align-items: center;
}

.list-element.table .buttons button,
.list-element .group .buttons button,
.list-element .subgroup .buttons button {
	border: 0;
	margin: 0;
	background-color: unset;
	color: var(--c1c);
	border: 1px solid var(--c1c);
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	text-transform: uppercase;
	padding: 10px 20px;
}
.list-element.table .buttons button:hover,
.list-element .group .buttons button:hover,
.list-element .subgroup .buttons button:hover {
	background-color: var(--c1);
	color: var(--c1c);
	border: 1px solid var(--c2);
}
.list-element .group .buttons button:nth-child(2),
.list-element .subgroup .buttons button:nth-child(2) {
	background-color: var(--c1c);
	color: var(--c1);
}
.list-element .group .buttons button:nth-child(2):hover,
.list-element .subgroup .buttons button:nth-child(2):hover {
	background-color: var(--c1);
	color: var(--c1c);
	border: 1px solid var(--c2);
}

.list-element .group .buttons button img {
	height: 30px;
	stroke: var(--c3c);
}

/* LIST TIMES DATEPOPUP */
.list.times-date .times {
	margin-bottom: 5px;
	font-weight: bold;
}
.list.times-date .small {
	display: flex;
	gap: 20px;
}
.list.times-date .small .load-numbers,
.list.times-date .small .drivers-number {
	display: flex;
	gap: 5px;
}

/**/

/* LIST TABLE TABLE POPUP */
.list.table-list .list-element {
	background-color: var(--c3);
	color: var(--c3c);
	text-transform: uppercase;
	font-weight: bold;
}
.list.table-list .list-element:hover {
	background-color: var(--c1);
	color: var(--c1c);
}
/**/

.horizzontal-menu {
	margin-bottom: 15px;
	display: flex;
	overflow: auto;
	gap: 10px;
}

.horizzontal-menu button {
	color: var(--c1);
	background-color: var(--c3);
	font-weight: bold;
	text-transform: uppercase;
	border: 0;
	padding: 10px 20px;
	border-radius: 5px;
}
.horizzontal-menu button:hover {
	color: var(--c2c);
	background-color: var(--c2);
}

.horizzontal-menu button.active {
	background-color: var(--c1);
	color: var(--c1c);
}

.bold {
	font-weight: 500;
}

.small {
	font-size: 12px;
}

.extra-small {
	font-size: 10px;
}

.input-group {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 10px;
	align-items: center;
	margin-bottom: 15px;
}

.input-group.cols-2 {
	grid-template-columns: 3fr 1fr;
}

.input-group input {
	height: 36px;
}

.input-group button {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 0;
	background-color: var(--c2);
	color: var(--c4c);
	border-radius: 5px;
	white-space: nowrap;
	padding: 10px 20px;
	text-transform: uppercase;
	font-weight: bold;
}

.input-group button:hover {
	background-color: var(--c1);
}

.form {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
}

.form .inline-form-group {
	display: flex;
	gap: 10px;
}

.form .inline-form-group > *:nth-child(1) {
	width: 100px;
}

.form .inline-form-group > *:nth-child(2) {
	flex-grow: 1;
}

.form button {
	border: 0;
	margin: 0;
	background-color: var(--c3);
	color: var(--c3c);
	width: 100%;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	font-weight: bold;
	border-radius: 5px;
	text-transform: uppercase;
}

.form button.success {
	background-color: var(--c1);
	color: var(--c1c);
}

.buttons-group {
	display: flex;
	gap: 10px;
}

.buttons-group.splitted {
	align-items: center;
	justify-content: space-between;
}

.buttons-group.centered {
	align-items: center;
}

.buttons-group.splitted input {
	width: auto;
}

.buttons-group.splitted button {
	width: auto;
}

.mb-2 {
	margin-bottom: 10px;
}

.mb-3 {
	margin-bottom: 15px;
}

.error-box {
	padding: 5px;
	background-color: red;
	color: white;
	font-size: 13px;
	white-space: pre-wrap;
}

.form-group .label {
	font-weight: 500;
	margin-bottom: 5px;
}

.form-group .description {
	margin-top: 5px;
	font-size: 12px;
}

.form-line {
	display: grid;
	gap: 10px;
}
.form-line-3 {
	grid-template-columns: repeat(3, 1fr);
}

.list-light-item {
	border-bottom: 1px solid #ccc;
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 50px;
	padding: 0 10px;
}
.list-light-item.inverted {
	background-color: var(--c1);
	color: var(--c1c);
	border-bottom: 0;
}

.horizzontal-group {
	display: flex;
	align-items: center;
	gap: 5px;
}

.horizzontal-group.large {
	justify-content: space-between;
}

.status-box {
	background-color: var(--c5);
	color: var(--c5c);
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	width: 100px;
	height: 20px;
	font-size: 11px;
	white-space: nowrap;
	border-radius: 5px;
	font-weight: 600;
}

.status-box.confirmed {
	background-color: var(--confirmed);
	color: var(--confirmed-c);
}

.status-box.preparation {
	background-color: var(--preparation);
	color: var(--preparation-c);
}

.status-box.ready {
	background-color: var(--ready);
	color: var(--ready-c);
}

.status-box.filled {
	background-color: var(--filled);
	color: var(--filled-c);
}

.button2 {
	border: 0;
	margin: 0;
	background-color: var(--c4);
	color: var(--c4c);
	width: 100%;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	border-radius: 5px;
	text-transform: uppercase;
}

.button2.success {
	background-color: var(--c3);
	color: var(--c3c);
}

.mt-1 {
	margin-top: 5px;
}

.mt-2 {
	margin-top: 10px;
}

.mt-3 {
	margin-top: 15px;
}

.slim-scroll::-webkit-scrollbar {
	width: 10px;
	height: 5px;
}

.slim-scroll::-webkit-scrollbar-track {
	background: #eee;
}

.slim-scroll::-webkit-scrollbar-thumb {
	background: #ccc;
}

.slim-scroll::-webkit-scrollbar-thumb:hover {
	background: rgb(143, 143, 143);
}

.hidden-small {
	display: none;
}

@media (min-width: 1280px) {
	.hidden-small {
		display: block;
	}
}

.hidden-large {
	display: block;
}

@media (min-width: 1280px) {
	.hidden-large {
		display: none;
	}
}

.grid-table {
	display: grid;
}

.grid-table > * {
	border-bottom: 1px solid #eee;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.grid-table > *.center {
	align-items: center;
}

.grid-table > *.right {
	align-items: flex-end;
}

.grid-table .success {
	color: var(--success);
}

.grid-table .warning {
	color: var(--warning);
}

.grid-table .error {
	color: var(--error);
}

.grid-table .info {
	color: var(--info);
}

.grid-table .grid-table-header {
	background-color: var(--c1);
	color: var(--c1c);
	text-transform: uppercase;
	font-weight: bold;
	font-size: 14px;
	text-align: center;
}

.grid-table .grid-table-header.small {
	font-size: 12px;
	padding: 5px;
}

.orders-filters {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 10px;
	margin-bottom: 15px;
}

.plus-minus-large {
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: center;
}

.plus-minus-large button {
	border: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 40px;
	line-height: 42px;
	border-radius: 100px;
	height: 50px;
	width: 50px;
}

.plus-minus-large button:nth-child(1) {
	background-color: var(--c3);
	color: var(--c3c);
}

.plus-minus-large button:nth-child(3) {
	background-color: var(--c1);
	color: var(--c1c);
}

.plus-minus-large input {
	width: 100px;
	height: 100px;
	border: 0;
	text-align: center;
	background-color: var(--c5);
	color: var(--c5c);
	font-size: 30px;
}

.text-center {
	text-align: center;
}

.table-popup {
	position: absolute;
	top: 0;
	left: 0;
	height: 100dvh;
	width: 100%;
	padding: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #00000095;
}

.table-popup > .content {
	padding: 10px;
	background-color: #fff;
	width: 100%;
	max-width: 500px;
	max-height: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.table-popup > .content .sidebar {
	flex-grow: 1;
	flex-shrink: 1;
}

.table-popup > .content .close-button {
	display: flex;
	justify-content: flex-end;
	flex-shrink: 0;
}

.table-popup > .content .close-button > button {
	width: 30px;
	height: 30px;
	border: 0;
	border-radius: 5px;
	background-color: #000;
	color: #fff;
	font-size: 25px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
}

.quotes {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	gap: 10px;
}

.quotes > div {
	background-color: var(--c1);
	color: var(--c1c);
	padding: 10px;
	border-radius: 5px;
}

.quotes > div .quotes-header {
	margin-bottom: 5px;
	text-align: center;
	font-weight: bold;
	font-size: 14px;
}

.quotes > div .quotes-content {
	background-color: var(--c1c);
	color: var(--c1);
	border-radius: 5px;
	padding: 10px;
}

.quotes-total {
	font-weight: bold;
	font-size: 30px;
	text-align: center;
	margin-top: 10px;
}

.lists-selector {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
	margin-bottom: 10px;
}

.menu-selector > div {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	margin-bottom: 10px;
}

.menu-selector > div > div:nth-child(2) {
	display: flex;
	align-items: center;
	gap: 5px;
	margin-bottom: 10px;
}

.menu-selector > div > div:nth-child(2) > div:nth-child(2) {
	width: 30px;
	text-align: center;
}

.plus-minus-generic {
	display: flex;
	gap: 10px;
	align-items: center;
	padding: 10px 0;
}

.plus-minus-generic button {
	border: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	line-height: 42px;
	border-radius: 100px;
	height: 40px;
	width: 40px;
	background-color: var(--c1);
	color: var(--c1c);
}

.plus-minus-large button:nth-child(1) {
	background-color: var(--c3);
	color: var(--c3c);
}

.plus-minus-generic input {
	width: 100px;
	height: 100px;
	border: 0;
	text-align: center;
	background-color: var(--c5);
	color: var(--c5c);
	font-size: 30px;
}

.products-color h3 {
	margin-top: 10px;
	margin-bottom: 5px;
	text-transform: uppercase;
}

.products-color h4 {
	margin-top: 20px;
	margin-bottom: 5px;
	text-transform: uppercase;
	background-color: var(--c1);
	color: #fff;
	padding: 5px;
	border-radius: 5px;
}

.products-color .product {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	border-bottom: 1px solid #ccc;
	padding: 3px 0;
}

.products-color .product:hover {
	background-color: #eee;
}

.products-color .buttons {
	display: flex;
	align-items: center;
}

.products-color button {
	width: 50px;
	height: 50px;
	border: 1px solid #fff;
	font-size: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
}

.expire {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100dvh;
	background-color: #00000095;
	display: flex;
	align-items: center;
	justify-content: center;
}

.expire .content {
	background-color: #fff;
	padding: 30px;
	border-radius: 5px;
	border: 10px solid var(--c2);
	max-width: 350px;
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.qr-button {
	display: flex;
	align-items: center;
	gap: 5px;
	font-weight: 700;
	font-size: 14px;
}

.qr-button button {
	background-color: transparent;
	color: var(--c1c);
	border: 1px solid var(--c1c);
	border-radius: 5px;
	text-transform: uppercase;
	font-weight: 700;
	display: flex;
	gap: 5px;
	padding: 5px;
	width: 40px;
	height: 40px;
}

.qr-button button svg {
	width: 100%;
	height: auto;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	background-color: #00000095;
	width: 100%;
	height: 100dvh;
}

.qr-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100dvh;
	display: flex;
	flex-direction: column;
	pointer-events: none;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	z-index: 2;
}

.qr-popup .qr-popup-container {
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	pointer-events: auto;
	background-color: #fff;
	border: 3px solid var(--c6);
	background-color: #fff;
	width: 100%;
	max-width: 300px;
	max-height: 500px;
}

.qr-popup header {
	background-color: var(--c1);
	color: var(--c1c);
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 5px;
	flex-shrink: 0;
	border-bottom: 1px solid #fff;
}

.qr-popup .content {
	flex-grow: 1;
	overflow: auto;
	padding: 5px;
}

.qr-popup footer {
	background-color: var(--c5);
	color: var(--c5c);
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 5px;
	flex-shrink: 0;
	border-bottom: 1px solid #fff;
}

.qr-popup .buttons-group {
	display: flex;
	align-items: center;
	gap: 5px;
	font-weight: 700;
	font-size: 14px;
}

.qr-code-container {
	text-align: center;
}

.qr-code-container a {
	color: #000;
	text-decoration: none;
}

.qr-code-container img {
	width: 100%;
}

.qr-code-container .pin {
	font-weight: bold;
	margin-top: 20px;
}

.qr-code-container .pin > div:nth-child(2) {
	font-size: 50px;
}

.qr-popup-close {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	border: 1px solid var(--c1c);
	font-size: 16px;
	border-radius: 5px;
	height: 39px;
	font-size: 30px;
	line-height: 0;
	padding-top: 3px;
	color: var(--c1c);
	width: fit-content;
	padding: 10px;
}

.extrasmall {
	font-size: 12px;
}
