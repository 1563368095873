.settings {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  background-color: #0000007d;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 30px;
}

.settings .settings-container {
  background-color: #ffffff;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.settings .settings-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: var(--c1);
  color: var(--c4c);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.settings .settings-header h2 {
  text-transform: uppercase;
}

.settings .settings-header button {
  border: 0;
  margin: 0;
  background-color: var(--c3);
  color: var(--c3c);
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  border-radius: 5px;
}

.settings .settings-columns {
  display: flex;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
}

.settings .settings-menu {
  padding: 10px;
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: var(--c3);
  border-bottom-left-radius: 10px;
}

.settings .settings-menu button {
  border: 0;
  margin: 0;
  background-color: var(--c1);
  color: var(--c1c);
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: left;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 600;
}
.settings .settings-menu button.selected {
  background-color: var(--c2);
}

.settings .settings-content {
  padding: 10px 20px;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 100%;
}
.settings .settings-content h2 {
  color: var(--c2);
  text-transform: uppercase;
}
.settings .settings-content .form-group .label {
  color: var(--c1);
  text-transform: uppercase;
  font-size: 14px;
  background-color: var(--c3);
  width: fit-content;
  padding: 5px;
}

.settings .settings-content select {
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-radius: 0px;
}
.settings .settings-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background-color: var(--c3);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.settings .settings-footer button {
  border: 0;
  margin: 0;
  background-color: var(--c2);
  color: var(--c1c);
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: left;
  border-radius: 5px;
  text-transform: uppercase;
  width: fit-content;
  padding: 20px 25px;
  font-size: 18px;
  font-weight: 600;
}
