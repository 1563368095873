.popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100dvh;
	background-color: #00000050;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	padding: 15px;
}

.popup .content {
	background-color: #ffffff;
	width: 100%;
	max-width: 800px;
	max-height: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 5px;
}

.popup.sm .content {
	max-width: 500px;
}

.popup.large .content {
	max-width: 1200px;
}

.popup.full .content {
	max-width: none;
	height: 100%;
}

.popup .content .title {
	font-size: 22px;
	color: var(--c3c);
	font-weight: bold;
	padding: 15px;
	display: flex;
	justify-content: space-between;
}
.popup .content .title .buttons-group {
	display: flex;
	gap: 5px;
}

.popup .content .title .buttons-group button {
	border: 0;
	background-color: var(--c2);
	color: var(--c2c);
	font-size: 14px;
	border-radius: 5px;
	padding: 5px 10px;
	text-transform: uppercase;
	font-weight: bold;
}

.popup .content .title .buttons-group button.icon {
	display: flex;
	gap: 5px;
	align-items: center;
}

.popup .content .title .buttons-group button.icon svg {
	width: 18px;
	height: 18px;
}

.popup .content .title .buttons-group button:hover {
	background-color: var(--c1);
	color: var(--c1c);
}

.popup .content .title button.close-button {
	background-color: var(--c1);
	color: var(--c1c);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 22px;
	width: 36px;
	height: 36px;
	padding: 0;
}

.popup .content .title .title-popup {
	font-size: 30px;
	flex-grow: 1;
	color: var(--c2);
	text-transform: uppercase;
}

.popup .subtitle-popup {
	font-size: 16px;
}

.popup .content .inner-content {
	background-color: #ffffff;
	padding: 0 15px 15px 15px;
	border-radius: 10px;
	height: 100%;
	overflow: auto;
}

.popup .content .inner-content h3 {
	margin-bottom: 20px;
}

.popup .horizzontal-group button {
	white-space: nowrap;
}

.popup .popup-footer {
	padding: 0 15px 15px 15px;
}
.popup .title-add-customer {
	align-items: center;
}
.popup .title-add-customer button {
	width: fit-content;
}
.popup .title-add-customer h3 {
	margin-bottom: 0px !important;
}
.popup .serach-input {
	background-color: #f7f7f7;
	padding: 10px;
	border-radius: 5px;
}
.popup .serach-input input {
	width: 100%;
}
.popup .serach-input label {
	text-wrap: nowrap;
}

/* pagamento elettronico*/
.electronic-payment .title-popup {
	text-align: left;
}
.electronic-payment {
	text-align: center;
}
.electronic-payment .inner-content {
	margin: 20px 0;
}
.electronic-payment h1 {
	background-color: var(--c1);
	width: fit-content;
	margin: auto;
	color: var(--c1c);
	border-radius: 5px;
	padding: 10px 20px;
}
.electronic-payment h5 {
	margin-top: 20px;
	color: var(--c2);
	font-style: italic;
}
.electronic-payment span {
	width: fit-content;
	margin: auto;
	display: block;
	margin-bottom: 10px;
}

.electronic-payment svg {
	animation: rotate-animation 1s infinite linear;
	text-align: center;
}

.electronic-payment .total-import {
	background-color: #f7f7f7;
	width: fit-content;
	padding: 30px 50px;
	margin: auto;
}

.electronic-payment button {
	background-color: var(--c2);
	color: var(--c2c);
	padding: 10px 20px;
}

.electronic-payment .error-retry h3 {
	color: var(--c2);
	font-weight: bold;
}

@keyframes rotate-animation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
