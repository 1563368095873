.variants-popup .variants-category {
	margin-bottom: 15px;
}

.variants-popup .variants-category.error .variants-categories-title {
	background-color: var(--c1);
	color: var(--c1c);
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-transform: uppercase;
}

.variants-popup .variants-category .request-tag {
	display: none;
}
.variants-popup .variants-category.error .request-tag {
	display: block;
	background-color: var(--c2);
	width: fit-content;
	height: fit-content;
	color: var(--c2c);
	border-radius: 5px;
	padding: 0 5px;
	font-weight: 600;
	margin-right: 10px;
}

.variants-popup .variants-categories-title {
	background-color: var(--c3);
	color: var(--c3c);
	border-radius: 5px;
	padding: 5px;
	margin-bottom: 10px;
	text-transform: uppercase;
}

.variants-popup .variant {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}
.variants-popup .variant-column {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 0 20px;
}

.variants-popup .variant .plus-minus {
	display: flex;
	align-items: center;
	gap: 5px;
}
.variants-popup .list-variant-ingredient {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: 50px;
}
.variants-popup .list-variant-ingredient .variant {
	justify-content: flex-start;
	gap: 10px;
}

.variants-popup .variant .plus-minus > div {
	width: 35px;
	text-align: center;
}

.variants-popup .variant .plus-minus button {
	border: 0;
	background-color: var(--c2);
	color: var(--c2c);
	display: flex;
	width: 35px;
	height: 35px;
	align-items: center;
	justify-content: center;
	font-size: 25px;
	border-radius: 5px;
}

.variants-popup .variant .plus-minus button.disabled {
	background-color: var(--c3);
	color: var(--c3c);
}

.variants-popup .variant input[type="checkbox"] {
	width: 20px;
	height: 20px;
	color: var(--c2);
}

.variants-buttons {
	padding: 10px;
}

.variants-button {
	border: 0;
	background-color: var(--c1);
	color: var(--c1c);
	display: flex;
	width: 100%;
	height: 40px;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	border-radius: 5px;
	text-transform: uppercase;
}

.variants-button.error {
	background-color: var(--c2);
}
