:root {
	--c1: #213740;
	--c1c: #ffffff;
	--c2: #bf0426;
	--c2c: #fff;
	--c3: #f0f0f0;
	--c3c: #000;
	--c4: #000;
	--c4c: #ffffff;
	--confirmed: #fdf1cf;
	--confirmed-c: #000;
	--preparation: #ffe0b3;
	--preparation-c: #000;
	--ready: #d9ffdb;
	--ready-c: #000;
	--filled: #b6eeff;
	--filled-c: #000;
}

.footer {
	display: flex;
	justify-content: space-between;
	gap: 20px;
	align-items: center;
	background-color: #fff;
	border-radius: 5px;
	padding: 10px;
	position: relative;
}

.footer .group {
	display: flex;
	gap: 20px;
	align-items: center;
	position: relative;
}

.footer .logo-container {
	position: relative;
}

.footer .logo-inner {
	display: flex;
	gap: 10px;
	align-items: flex-end;
	font-weight: bold;
	font-size: 11px;
}

.footer .logo {
	width: 70px;
}

.footer .shop {
	font-size: 11px;
	font-weight: bold;
	margin-top: -3px;
}

.footer .menu-container {
	display: none;
	position: absolute;
	left: 0px;
	bottom: 70px;
	background-color: #fff;
	padding: 10px;
	flex-direction: column;
	gap: 5px;
	border-radius: 5px;
	box-shadow: 0px 0px 10px #00000090;
	z-index: 2;
}

.footer .menu-container.open {
	display: flex;
}

.footer .menu-container button {
	background-color: var(--c1);
	color: var(--c1c);
	border-radius: 5px;
	border: unset;
	font-size: 14px;
	font-weight: 800;
	text-transform: uppercase;
	padding: 10px;
	text-align: left;
	width: 220px;
}

.footer .menu-container button:hover {
	color: var(--c2);
}

.footer .menu-container .shop-info {
	text-align: left;
}

.footer .menu-container .shop-info .info {
	font-weight: 400;
	font-size: 10px;
}

.footer .buttons-container {
	display: flex;
	gap: 10px;
	position: relative;
}

.footer .buttons-container > button {
	border-radius: 5px;
	font-size: 20px;
	border: unset;
	background-color: var(--c3);
	color: var(--c3c);
	padding: 15px;
}

.footer .buttons-container button:hover {
	background-color: var(--c3);
	color: var(--c1);
}

.footer .buttons-container button.icon {
	display: flex;
	gap: 5px;
	align-items: center;
}
